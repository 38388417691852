import $ from "$";
import Mark from "mark.js";
import * as cookie from "../utils/cookie";
import { isEmptyOrSpaces } from "../utils/tests";
import * as us from "./user_settings";
// noinspection SpellCheckingInspection
const speechPronunciationArray = [
    [/Minecraft/gmi, "Maïnecraft"],
    [/Collider/gmi, "Collaïdeur"],
    [/(?<=\D)\/(?=\D+)/gmi, " barre oblique "],
    [/(?<=\S)Action\b/gm, "Akshönne"],
    [/On(?=[A-Z])+/gm, "Onne"],
    [/Enter/gmi, "Ainneteur"],
    [/Load/gmi, "Lôde"],
    [/Management/gmi, "Managemainnte"],
    [/Namespace/gmi, "Nèmspèce"],
    [/#/gmi, "Numéro"],
    [/MainIsland/gmi, "Mèïne-Ail-Lande"],
    [/https?:\/\/.*?(\s+|$)/gmi, ". Lien URL. "],
    [/\Wex\.\W/gmi, ". Par exemple, "],
    [/:/gm, ". "],
    [/material/gmi, "matérial"]
];
export function init() {
    if ('speechSynthesis' in window) {
        us.appendItem(us.TYPE.SUBMENU, "narrator_submenu", "Narrateur");
        us.appendItem(us.TYPE.SELECT, "voiceSelector", "Voix : ", "narrator_submenu");
        us.appendItem(us.TYPE.BREAK, undefined, undefined, "narrator_submenu");
        us.appendItem(us.TYPE.BREAK, undefined, undefined, "narrator_submenu");
        us.appendItem(us.TYPE.RANGE, "voiceSpeedRange", `Vitesse : <span id="voiceSpeedRangeVal">1.0</span>`, "narrator_submenu", `min="0.5" max="2" step="0.1"`);
        appendSliderlinkButtons();
        loadVoicesAsync();
    }
}
export function appendSliderlinkButtons() {
    $(".sliderlink").append($("<div class='speechButton'><i class=\"fa-solid fa-circle-play\"></i></div>"));
    $(".sliderlink .speechButton").each((i, el) => {
        el.addEventListener("click", bindSpeechButton);
    });
}
function formatSpeech(string) {
    for (let map of speechPronunciationArray) {
        string = string.replace(map[0], map[1]);
    }
    return string;
}
const markOptions = { "separateWordSearch": false, "acrossElements": true, "debug": true };
let activeSliderlinkMarkInstance;
let currVoice;
let currRate = 1.0;
function voicesChanged() {
    let allVoices = window.speechSynthesis.getVoices();
    let voices = allVoices.filter(voice => {
        return voice.lang === ("fr-FR") || voice.lang === ("fr-CA");
    });
    let natural = voices.filter(voice => {
        return voice.name.includes("(Natural)");
    });
    if (natural.length > 0)
        voices = natural;
    $("#voiceSelector>option").remove();
    for (let i = 0; i < voices.length; i++) {
        $("#voiceSelector").append($(`<option value='${i}'>${voices[i].name}</option>`));
    }
    let voiceIndex = cookie.get("tts_voice");
    if (!isEmptyOrSpaces(voiceIndex) && parseInt(voiceIndex) < voices.length) {
        document.querySelector(`#voiceSelector>option[value='${parseInt(voiceIndex)}']`).setAttribute("selected", "true");
        currVoice = voices[parseInt(voiceIndex)];
    }
    else {
        currVoice = voices[0];
        cookie.set("tts_voice", "0");
    }
    document.querySelector("#voiceSelector").addEventListener("change", () => {
        let newVoiceIndex = $("#voiceSelector>option:selected")[0].getAttribute("value");
        cookie.set("tts_voice", newVoiceIndex);
        currVoice = voices[parseInt(newVoiceIndex)];
    });
}
function loadVoicesAsync() {
    voicesChanged();
    window.speechSynthesis.onvoiceschanged = voicesChanged;
    let speedRangeInput = $("#user_settings_menu #voiceSpeedRange")[0];
    let cookieRate = cookie.get("tts_rate");
    if (!isEmptyOrSpaces(cookieRate)) {
        currRate = parseFloat(cookieRate);
    }
    speedRangeInput.setAttribute("value", currRate.toString());
    $("#user_settings_menu #voiceSpeedRangeVal").text(currRate);
    speedRangeInput.addEventListener("input", e => {
        $("#user_settings_menu #voiceSpeedRangeVal").text(e.target.value);
    });
    speedRangeInput.addEventListener("change", e => {
        cookie.set("tts_rate", e.target.value);
        currRate = parseFloat(e.target.value);
    });
}
function bindSpeechButton(e) {
    window.speechSynthesis.cancel();
    $(".sliderlink .pauseSpeechButton").each((i, el) => el.classList.add("retracting"));
    setTimeout(() => {
        let btn = $("<div class='speechButton retracting'><i class=\"fa-solid fa-circle-play\"></i></div>");
        let oldBtn = $(".sliderlink .pauseSpeechButton.retracting");
        oldBtn.parent().append(btn);
        btn[0].classList.remove("retracting");
        oldBtn.remove();
        btn[0].addEventListener("click", bindSpeechButton);
    }, 200);
    let sliderlink = e.target.closest(".sliderlink");
    let button = sliderlink.querySelector(".speechButton");
    let pauseBtn = $("<div class='pauseSpeechButton retracting'><i class=\"fa-solid fa-circle-stop\"></i></div>");
    if (typeof activeSliderlinkMarkInstance === "object")
        activeSliderlinkMarkInstance.unmark(markOptions);
    activeSliderlinkMarkInstance = new Mark(sliderlink);
    button.classList.add("retracting");
    setTimeout(() => {
        $(button).parent().append(pauseBtn);
        pauseBtn[0].classList.remove("retracting");
        $(button).remove();
        pauseBtn.bind("click", () => {
            window.speechSynthesis.cancel();
            activeSliderlinkMarkInstance.unmark(markOptions);
            let btn = $("<div class='speechButton retracting'><i class=\"fa-solid fa-circle-play\"></i></div>");
            pauseBtn[0].classList.add("retracting");
            setTimeout(() => {
                pauseBtn.parent().append(btn);
                btn[0].classList.remove("retracting");
                pauseBtn.remove();
                btn[0].addEventListener("click", bindSpeechButton);
            }, 200);
        });
    }, 200);
    let sentenceArray = [...sliderlink.innerText.match(/([^.!?:\n]+\s{2,}?)|(([^.!?:]+\.\S+)+)|([^.!?:]+(\Wex\.\W)[^.!?:]+[.!?:]+)|([^.!?:]+[.!?:]+)|([^.!?:]+$)/gm)];
    // let sentenceArray = [...sliderlink.textContent.matchAll(/([^\.!\?\n]+\s{2,}?)|([^\.!\?]+(\.\w+)+[^\.!\?]+[\.!\?]+)|([^\.!\?]+(\Wex\.\W)[^\.!\?]+[\.!\?]+)|([^\.!\?]+[\.!\?]+)|([^\.!\?]+$)/gm)];
    // let sentenceArray = [...sliderlink.textContent.matchAll(/([^.!?\n]*\S+(?:\.\S+)?[^.!?\n]*[.!?\n]+)|([^.!?\n]+(?:[.!?\n]+|$))/g)];
    sentenceArray = sentenceArray.filter(sentence => sentence.match(/\S/g));
    for (let i = 0; i < sentenceArray.length; i++) {
        let msg = new SpeechSynthesisUtterance();
        if (i === 0) {
            let toMark = sentenceArray[0].trim();
            activeSliderlinkMarkInstance.mark(toMark, markOptions);
        }
        msg.lang = "fr";
        msg.text = formatSpeech(sentenceArray[i].trim());
        msg.addEventListener("error", e => {
            console.error(e.name);
        });
        if (typeof currVoice !== 'undefined') {
            msg.voice = currVoice;
        }
        msg.rate = currRate;
        window.speechSynthesis.speak(msg);
        msg.addEventListener("end", () => {
            activeSliderlinkMarkInstance.unmark(markOptions);
            if (i < sentenceArray.length - 1) {
                let toMark = sentenceArray[i + 1].trim();
                activeSliderlinkMarkInstance.mark(toMark, markOptions);
            }
            else {
                pauseBtn[0].classList.add("retracting");
                setTimeout(() => {
                    let btn = $("<div class='speechButton retracting'><i class=\"fa-solid fa-circle-play\"></i></div>");
                    $(pauseBtn).parent().append(btn);
                    btn[0].classList.remove("retracting");
                    $(pauseBtn).remove();
                    btn[0].addEventListener("click", bindSpeechButton);
                }, 200);
            }
        });
    }
    //e.preventDefault();
    //e.stopPropagation();
}
