import * as userSettings from "../modules/user_settings";
import { bindCheckboxToCookie } from "../modules/user_settings";
import { isEmptyOrSpaces } from "../utils/tests";
import * as locale from "../utils/locale";
import $ from "$";
export function init() {
    userSettings.appendItem(userSettings.TYPE.SUBMENU, "dashboard_submenu", "Tableau de bord", "display_submenu");
    userSettings.appendItem(userSettings.TYPE.CHECKBOX, "calendar_banner", "Bannière de rappel", "dashboard_submenu");
    bindCheckboxToCookie("calendar_banner", "calendar_banner", banner_checked, banner_unchecked, "true");
    userSettings.appendItem(userSettings.TYPE.CHECKBOX, "calendar_display", "Calendrier", "dashboard_submenu");
    bindCheckboxToCookie("calendar_display", "calendar_display", calendar_display_checked, calendar_display_unchecked, "true");
    userSettings.appendItem(userSettings.TYPE.CHECKBOX, "welcome_display", "Message d'accueil", "dashboard_submenu");
    bindCheckboxToCookie("welcome_display", "welcome_display", welcome_checked, welcome_unchecked, "true");
}
function calendar_display_checked() {
    $(".block_calendar_month").show();
}
function calendar_display_unchecked() {
    $(".block_calendar_month").hide();
}
function welcome_checked() {
    $("#welcome_banner").show();
}
function welcome_unchecked() {
    $("#welcome_banner").hide();
}
function FormatTitle(title) {
    if (title.includes("Graphiste") && title.includes("Concept") && title.includes("Prog")) {
        title = title.replace("Programmeur, Concepteur, Graphiste", "Mixte");
        title = "🧮 " + title;
    }
    else {
        if (title.includes("Minecraft")) {
            title = "⛏️ " + title;
        }
        if (title.includes("Graphiste")) {
            title = "🎨 " + title;
        }
        if (title.includes("Concept")) {
            title = "🧭 " + title;
        }
        if (title.includes("Prog")) {
            title = "🧩 " + title;
        }
        if (title.includes("Lab")) {
            title = "🧪 " + title;
        }
    }
    return title;
}
function banner_checked() {
    let today = document.querySelector(".calendarmonth.calendartable td.today");
    if (!isEmptyOrSpaces(today)) {
        let cours_li = today.querySelectorAll("li"); /* This always gets an extra hidden element */
        if (today.classList.contains("hasevent") && cours_li.length > 0) {
            let banner = $(`<div id="courseAlertBanner" class="alert alert-success-outline alt-style" style="text-align:center;">
            <span lang="fr_ca" class="multilang"><h2 style="font-family:rocknroll one; margin: 1rem;">Aujourd'hui : ${cours_li.length - 1} cours à l'horaire!</h2></span>
            <span lang="en" class="multilang"><h2 style="font-family:rocknroll one; margin: 1rem;">Today : ${cours_li.length - 1} classes scheduled!</h2></span>
        </div>`);
            for (let i = 0; i < cours_li.length - 1; i++) {
                let a = cours_li[i].querySelector("a");
                let title = a.getAttribute("title");
                let eventId = a.getAttribute("data-event-id");
                title = FormatTitle(title);
                let link = $(`<a class="btn btn-success" style="margin:0.5rem;">${title}</a>`);
                link.bind("click", () => { $(`a[data-event-id="${eventId}"]`)[0].click(); });
                banner.append(link);
            }
            document.querySelectorAll("aside#block-region-content>section")[0].insertAdjacentElement("afterend", banner[0]);
        }
        else {
            document.querySelectorAll("aside#block-region-content>section")[0].insertAdjacentElement("afterend", $(`<div id="courseAlertBanner" class="alert alert-danger-outline alt-style" style="text-align:center;">
            <h2 style="font-family:rocknroll one;margin: 1rem;">
                ${locale.format("fr_ca", "Aujourd'hui : Pas de cours!")}
                ${locale.format("en", "Today : Nothing planned!")}
            </h2>
            <h4 style="margin-bottom: 1rem;font-family:Roboto" class="">
                ${locale.format("fr_ca", "C'est le temps de te reposer.")}
                ${locale.format("en", "It's time to rest.")} 🍵
            </h4>
        </div>`)[0]);
        }
    }
}
function banner_unchecked() {
    let courseAlertBanner = document.getElementById("courseAlertBanner");
    if (!isEmptyOrSpaces(courseAlertBanner)) {
        courseAlertBanner.remove();
    }
}
