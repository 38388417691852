import $ from "$";
import * as cookie from "./utils/cookie";
import * as tests from "./utils/tests";
import * as utils from "./utils/utils";
import * as lightbox from "./components/lightbox";
import * as tts from "./modules/text_to_speech";
import * as uxc from "./modules/ux_convertor";
import * as bootstrap from "bootstrap";
import * as debug from "./utils/debug";
import * as userSettings from "./modules/user_settings";
import * as metaInfo from "./components/meta_info";
import * as cal from "./components/calendar";
import * as bookmarks from "./components/bookmarks";
import { isEmptyOrSpaces } from "./utils/tests";
import * as locale from "./utils/locale";
// ## button visuals //
document.querySelectorAll(".activitynavitems>.prev>a, #prev-activity-link").forEach(e => {
    e.classList.add("btn", "btn-secondary");
    e.parentElement.classList.remove("mr-3");
});
document.querySelectorAll("#next-activity-link, .activitynavitems>.next>a").forEach(e => {
    e.classList.add("btn", "btn-primary");
    e.parentElement.classList.remove("ml-3");
    e.parentElement.style.marginLeft = "0.75rem";
    e.innerHTML = e.innerHTML.replace(/(\S*)$/m, i => {
        return "<span class='white-outline hue270'>" + i + "</span>";
    });
});
// ## section Activity « spotlight » hover effect //
const handleOnMouseMove = (e) => {
    const { currentTarget: target } = e;
    const rect = target.getBoundingClientRect(), x = e.clientX - rect.left + 100, y = e.clientY - rect.top + 50;
    target.style.setProperty("--mouse-x", `${x}px`);
    target.style.setProperty("--mouse-y", `${y}px`);
};
document.querySelectorAll(".activity-item:not(.activityinline) .stretched-link").forEach(card => {
    card.addEventListener("mousemove", handleOnMouseMove);
});
// Reorganize page elements //
if (document.querySelector("#page-navbar ol.breadcrumb") && document.querySelector("div.activitynavitems")) {
    document.querySelector("#page-navbar ol.breadcrumb").after(document.querySelector("div.activitynavitems"));
}
if (document.querySelector(".meta.d-flex.role.role-Étudiant")) {
    document.querySelector("body").classList.add("role-student");
}
if (document.querySelector("#page-navbar") && document.querySelector("#page-header>.w-100")) {
    document.querySelector("#page-header>.w-100").after(document.querySelector("#page-navbar"));
}
// ## Activity meta info
metaInfo.formatActivities();
metaInfo.CleanEmptyDescriptions();
let redActivityTypeTexts = document.querySelectorAll(".red .activitytitle .media-body .text-uppercase.small, .red-pixel .activitytitle .media-body .text-uppercase.small");
let goldActivityTypeTexts = document.querySelectorAll(".gold .activitytitle .media-body .text-uppercase.small, .gold-pixel .activitytitle .media-body .text-uppercase.small");
let blueActivityTypeTexts = document.querySelectorAll(".blue .activitytitle .media-body .text-uppercase.small, .blue-pixel .activitytitle .media-body .text-uppercase.small");
redActivityTypeTexts.forEach((el, i) => {
    if (el.closest(".blue, .red, .gold, .blue-pixel, .red-pixel, .gold-pixel").classList.contains("custom-quest-label"))
        return;
    el.innerHTML = locale.format("other", "Quête #" + (i + 1)) + locale.format("en", "Quest #" + (i + 1));
});
goldActivityTypeTexts.forEach((el, i) => {
    if (el.closest(".blue, .red, .gold, .blue-pixel, .red-pixel, .gold-pixel").classList.contains("custom-quest-label"))
        return;
    el.innerHTML = locale.format("other", "Quête #" + (i + 1)) + locale.format("en", "Quest #" + (i + 1));
});
blueActivityTypeTexts.forEach((el, i) => {
    if (el.closest(".blue, .red, .gold, .blue-pixel, .red-pixel, .gold-pixel").classList.contains("custom-quest-label"))
        return;
    el.innerHTML = locale.format("other", "Quête #" + (i + 1)) + locale.format("en", "Quest #" + (i + 1));
});
/*let sliderlinkBindingAttempts = 0;

function attemptSliderlinkBinding() {
    sliderlinkBindingAttempts++;
    if(document.querySelector(".sliderlink") !== null){
        resizeSlider();
        bindScrollspy();
    } else {
        console.log("could not bind");
        if(sliderlinkBindingAttempts < 100) // dismiss check after 100 attempts * 100ms = 10s
            setTimeout(attemptSliderlinkBinding, 100);
    }
};*/
// # SLIDERLINK FUNCTIONS //
// ## Activate a specific sliderlink on command //
// ## Sliderlink init + binding function //
function sliderlinkClick(ev) {
    if (ev.ctrlKey) {
        let sl = (ev.target).closest(".sliderlink");
        document.getElementById(sl.getAttribute("href").replace("#", "")).scrollIntoView();
    }
}
//## Improve completion badge visuals //
let completionBadge = document.querySelector("#page-mod-page-view div.automatic-completion-conditions>span.badge");
if (completionBadge !== null) {
    completionBadge.style.cssText += "margin-left:20px; font-size:0.8rem; padding: 0.4rem 0.5rem; vertical-align:middle;";
    let icon = completionBadge.querySelector("span>i");
    icon.style.cssText += "font-size:0.7rem; vertical-align:middle; line-height:0.9rem; mergin-right:2px;";
    document.querySelector("#page-header .page-header-headings>h1").appendChild(completionBadge);
    document.querySelector("div.activity-header").style.display = "none";
    let qs_badges = document.querySelector("#intro #activity-description");
    if (qs_badges) {
        qs_badges.style.paddingTop = "0";
        let navbar = document.querySelector("#page-navbar");
        navbar.parentElement.insertBefore(qs_badges, navbar);
    }
}
// ## Add an invisible element to quests (fixes positioning)
let allLabels = document.querySelectorAll("li.activity.activity-wrapper.label");
if (allLabels.length > 0) {
    allLabels.forEach(label => {
        let invis = document.createElement("div");
        invis.style.width = "calc(33.33% - 0.33rem)";
        label.parentElement.insertBefore(invis, label);
    });
}
if (document.querySelector(".role-student .single-section")) {
    document.querySelector(".secondary-navigation").style.display = "none";
    if (document.querySelector(".progress.courseview")) {
        document.querySelector(".single-section .course-section-header").after(document.querySelector(".progress.courseview"));
    }
}
// # REDACTION TOOLS
// FIXME there's probably a way to simply dismiss this if the page is invalid.
let pageEditableChecks = 0;
function checkPageEditable() {
    setTimeout(() => {
        if (document.getElementById("id_pageeditable") == null) {
            pageEditableChecks++;
            if (pageEditableChecks < 200) // dismiss check after roughly 20 seconds
                checkPageEditable();
        }
        else {
            observeEditor();
            document.getElementById("id_pageeditable").style.height = "1600px";
            let linkList = document.querySelectorAll(".sliderlink");
            linkList.forEach(slider => slider.addEventListener("click", sliderlinkClick));
        }
    }, 100);
}
if (document.querySelector("body.pagelayout-admin #id_contentsection #fitem_id_page")) {
    checkPageEditable();
    let element = document.querySelector("#fitem_id_page>.col-md-9");
    element.classList.remove("col-md-9");
    element.classList.add("col-md-12");
}
// ## Automatically add slides when a sliderlink is added
let observeEditor = function () {
    const elementsToObserve = document.querySelectorAll(".editor_atto_content");
    let linkList = document.querySelectorAll(".sliderlink");
    let imageList = document.querySelectorAll(".slides>div");
    let observer = new MutationObserver(() => {
        linkList = document.querySelectorAll(".sliderlink");
        imageList = document.querySelectorAll(".slides>div");
        if (linkList.length > imageList.length) {
            let child = utils.cloneElement(imageList[imageList.length - 1]);
            //imageList[imageList.length - 1].cloneNode(true);
            child.setAttribute("id", `img${linkList.length}`);
            child.querySelectorAll(".slidenumber")[0].innerText = linkList.length.toString();
            linkList[linkList.length - 1].setAttribute("href", `#img${linkList.length}`);
            imageList[0].parentElement.appendChild(child);
            reflowSliderlinks();
        }
        else if (linkList.length < imageList.length) {
            imageList[imageList.length - 1].remove();
            reflowSliderlinks();
        }
    });
    elementsToObserve.forEach(element => observer.observe(element, { subtree: true, childList: true }));
};
function reflowSliderlinks() {
    let linkList = document.querySelectorAll(".sliderlink");
    let imageList = document.querySelectorAll(".slides>div");
    linkList.forEach((link, index) => {
        link.setAttribute("href", "#img" + (index + 1));
        link.addEventListener("click", sliderlinkClick);
    });
    imageList.forEach((image, index) => {
        image.setAttribute("id", "img" + (index + 1));
    });
}
// ## Dynamically add lightbox behavior
let images = document.querySelectorAll(".img-responsive.atto_image_button_text-bottom,.img-fluid.atto_image_button_text-bottom, .img-responsive.atto_image_button_middle, .slides img, .atto_bsgrid.container-fluid img");
images.forEach(img => img.addEventListener("click", lightbox.show));
document.getElementById("modal-course").addEventListener("click", lightbox.hide);
document.getElementById("modal-close").addEventListener("click", lightbox.hide);
function removeSpoiler() {
    this.classList.remove("spoiler");
}
// ## Remove %000% sort garbage
let courseicon_list_items = document.querySelectorAll(".courseicon.list");
courseicon_list_items.forEach(item => {
    item.closest(".activity.activity-wrapper").style.width = "100%";
    item.closest(".activity-item").style.padding = "0";
});
function removeSortGarbage() {
    console.log("removing");
    let titles = document.querySelectorAll("li.breadcrumb-item>a, .page-header-headings>h1, #page-my-index .course-listitem .aalink.coursename, #page-my-index .card .course-info-container .aalink.coursename>span:last-child");
    titles.forEach(e => {
        e.innerHTML = e.innerHTML.replace(/%\d{3}%/gm, "");
    });
    document.title = document.title.replace(/%\d{3}%/gm, "");
}
if (document.querySelector('div[data-region="courses-view"]')) {
    const coursenameObserver = new MutationObserver(() => {
        coursenameObserver.disconnect();
        removeSortGarbage();
        setTimeout(() => coursenameObserver.observe(document.querySelector('div[data-region="courses-view"]'), {
            subtree: true,
            childList: true
        }), 50);
    });
    coursenameObserver.observe(document.querySelector('div[data-region="courses-view"]'), {
        subtree: true,
        childList: true
    });
}
removeSortGarbage();
// # Activity breadcrumb and header visuals
if (document.querySelector("#page-course-view-topics div.single-section") && !tests.isEditMode()) {
    const breadcrumb = document.querySelector(".breadcrumb");
    const headerElement = document.querySelector(".page-header-headings>h1");
    const moduleElement = document.querySelector(".single-section .course-section-header>.sectionname>a");
    const chapterLink = window.location.href.replace(/&section=\d*/g, "");
    const chapterBreadcrumbItem = document.createElement("li");
    chapterBreadcrumbItem.classList.add("breadcrumb-item");
    chapterBreadcrumbItem.innerHTML = '<a href="' + chapterLink + '">' + headerElement.innerText + '</a>';
    const moduleTitle = moduleElement.textContent;
    const moduleBreadcrumbItem = document.createElement("li");
    moduleBreadcrumbItem.classList.add("breadcrumb-item");
    moduleBreadcrumbItem.innerText = moduleTitle;
    breadcrumb.appendChild(chapterBreadcrumbItem);
    breadcrumb.appendChild(moduleBreadcrumbItem);
    headerElement.innerText = moduleTitle;
    moduleElement.remove();
}
metaInfo.formatChapter();
metaInfo.formatModules();
// ## Reduce size of completion status
for (const e of document.querySelectorAll(".automatic-completion-conditions>span")) {
    e.querySelector("strong").innerText = e.querySelector("strong").innerText.slice(0, -1).trimEnd();
    e.querySelector("span:last-child").remove();
}
// FIXME Old « Show password » checkbox, never worked
/* let checkbox = document.createElement("input");
let label = document.createElement("label");
label.innerText = "Afficher le mot de passe";
checkbox.setAttribute("type", "checkbox");
checkbox.style.display = "inline-block";
checkbox.style.margin = "16px 16px 16px 0";
checkbox.addEventListener('click', togglePassword);

function togglePassword() {
    var pw = document.getElementById("id_activitypassword");
    if (pw.type === "password") {
        pw.type = "text";
    } else {
        pw.type = "password";
    }
}

let empty = document.createElement("div");
empty.classList.add("col-md-3");
let container = document.createElement("div");
container.classList.add("col-md-9");

container.appendChild(checkbox);
container.appendChild(label);

if(document.getElementById("id_activitypassword")){
    document.getElementById("id_activitypassword").parentElement.parentElement.appendChild(empty);
    document.getElementById("id_activitypassword").parentElement.parentElement.appendChild(container);
}*/
// # PAGE INITIALIZATION
// fixme TEMP
if (isEmptyOrSpaces(cookie.get("tmp_should_reset_ux"))) {
    cookie.set("tmp_should_reset_ux", "true");
}
userSettings.init();
tts.init();
uxc.init();
cal.init();
bookmarks.init();
// ## After init
let spoilers = document.querySelectorAll("div.spoiler");
spoilers.forEach(spoiler => spoiler.addEventListener("click", removeSpoiler));
// ## Better underline visuals
let underlineArray = document.querySelectorAll("#page-content u");
underlineArray.forEach(e => {
    if (!tests.isEmptyOrSpaces(e.firstChild)
        && !tests.isEmptyOrSpaces(e.firstChild.style)
        && !tests.isEmptyOrSpaces(e.firstChild.style.color)) {
        e.style.cssText += `text-decoration-color: ${e.firstChild.style.color};`;
    }
});
if (document.querySelector(".bottom-activity-navigation hr")) {
    document.querySelector(".bottom-activity-navigation hr").remove();
    document.querySelector(".bottom-activity-navigation").classList.remove("mt-3");
}
// ## Better visuals for availability + description boxes
let descriptions = document.querySelectorAll(".description-inner");
descriptions.forEach(el => {
    if (el.innerHTML.includes("soit marquée comme achevée")) {
        let link = el.querySelector("a");
        if (link !== null && link.href !== null) {
            el.innerHTML = '🔒️ <a class="stretched-link" href="' + link.href + ';"><strong>La quête précédente</strong></a> doit être complétée.';
        }
        else {
            el.innerHTML = '🔒️ <strong>La quête précédente</strong> doit être complétée.';
        }
    }
    if (el.innerHTML.includes("vous ayez saisi le bon mot de passe")) {
        let link = el.querySelector("a.availability_password-popup");
        if (link !== null && link.getAttribute("href") !== null) {
            el.innerHTML = '🗝️ <a class="availability_password-popup stretched-link" href="' + link.getAttribute("href") + '"><strong>Entrez le mot de passe pour continuer.</strong></a>';
        }
        else {
            el.innerHTML = '🗝️ <a class="availability_password-popup stretched-link"><strong>Entrez le mot de passe pour continuer.</strong></a>';
        }
    }
});
// # Scroll handling
/* function bak_handleSliderlinkScroll_inThreeTypes(e:WheelEvent) {
    // Backup function. Can replace handleSliderlinkScroll.
    // Hovering sliderlinks gives new behavior : activating previous/next block.
    // Not hovering gives old behavior : scrolling the page, and checking element in middle.
    resizeSlider();

    if (isScrolling)
        return;

    //timeoutScroll();

    let page = $("#page")[0];

    if (hoveringSliderlinks) {
        if (e.deltaY < 0) {
            if (activatePreviousSliderlink())
                e.preventDefault();
        } else if (e.deltaY > 0) {
            if (activateNextSliderlink())
                e.preventDefault();
        }
    } else if (page.scrollTop === 0 || Math.abs(page.scrollHeight - page.scrollTop - page.clientHeight) < 1) { // Scroll is at top or bottom
        if (e.deltaY < -1 && !$(".sliderlink:first-child").hasClass("active")) {
            activatePreviousSliderlink();
        } else if (e.deltaY > 1 && !$(".sliderlink:last-child").hasClass("active")) {
            activateNextSliderlink();
        }
    } else {
        let centeredElementRight = document.elementFromPoint(
            (document.body.offsetWidth / 3) * 2, document.body.offsetHeight / 2
        ).closest(".sliderlink");

        let centeredElementLeft = document.elementFromPoint(
            (document.body.offsetWidth / 3), document.body.offsetHeight / 2
        ).closest(".sliderlink")

        if (centeredElementRight && centeredElementRight !== null)
            activateSliderlink(centeredElementRight, false);
        else if (centeredElementLeft && centeredElementLeft !== null)
            activateSliderlink(centeredElementLeft, false);
    }
} */
// # Page Loader transition //
/*
window.onload = function() {
    document.querySelector(".loader").classList.remove('loader--active');
    // noinspection JSUndeclaredVariable
    pageLoaded = true;
};

window.onunload = function() {
    document.querySelector(".loader").classList.add('loader--active');
};

let clickedLink = "";

function bindLoader() {
    if(document.querySelector("body.editing") === null){
        for(const el of document.querySelectorAll('a[href^="https://moodle"], a[href^="https://moodle"]::after ')){
            el.onclick = e => {
                clickedLink = e.target.href;
                e.preventDefault();
                document.querySelector(".loader").classList.add('loader--active');
                // noinspection JSUndeclaredVariable
                pageLoaded  = true;
                setTimeout(() => {
                    window.location.href = clickedLink;
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                }, 1000);
            };
        }
    }
}

bindLoader();
setTimeout(bindLoader, 100);
setTimeout(bindLoader, 250);
setTimeout(bindLoader, 500);
setTimeout(bindLoader, 1000);
setTimeout(bindLoader, 2000);
*/
//const courseID = document.querySelector("body").classList.toString().match(/(?<=course-)\d+/gm);
// ## Courseindex collapser //
function collapseCourseindex() {
    for (const item of document.querySelectorAll(".courseindex-item-content.collapse.show")) {
        if (item.parentElement.querySelector("*.pageitem") === null) {
            item.classList.remove("show");
            item.parentElement.querySelector("div.courseindex-section-title>a.courseindex-chevron").classList.add("collapsed");
        }
        /*document.querySelector('a[aria-controls="' + item.id + '"]').onclick = e => {
            cookie.set("courseindex-" + courseID + "-interacted", "true");
        }*/
    }
}
//if (cookie.get("courseindex-" + courseID + "-interacted") === ""){
collapseCourseindex();
setTimeout(collapseCourseindex, 100);
setTimeout(collapseCourseindex, 250);
setTimeout(collapseCourseindex, 500);
setTimeout(collapseCourseindex, 1000);
setTimeout(collapseCourseindex, 2000);
//}
if (tests.isEmptyOrSpaces(document.querySelector(".activity-header")) && document.querySelector(".activity-header") !== null) {
    document.querySelector(".activity-header").style.display = "none";
}
document.addEventListener('keydown', e => {
    if (e.ctrlKey && e.key === 's') {
        e.preventDefault();
        let description = document.querySelector("#id_introeditoreditable");
        if (!tests.isEmptyOrSpaces(description)) {
            for (let checkbox of document.querySelectorAll('input[name="showdescription"], input[name="printintro"]')) {
                checkbox.setAttribute("value", "1");
            }
        }
        if (document.querySelector("#page-mod-page-mod") !== null) {
            $("#id_submitbutton2").click();
        }
        if (document.querySelector("body.pagelayout-admin") !== null) {
            $("form#adminsettings button[type=\"submit\"]").click();
        }
    }
});
$("#page").after($("#modal-course"));
// ## Bootstrap popover init//
let myDefaultAllowList = bootstrap.Tooltip.Default.allowList;
myDefaultAllowList.kbd = [];
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
// noinspection JSUnusedLocalSymbols
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
// noinspection JSUnusedLocalSymbols
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, {
    allowList: myDefaultAllowList,
    html: true
}));
if (tests.isRoleTeacher())
    $(".availabilityinfo .stretched-link").each((i, el) => el.classList.remove("stretched-link"));
for (let sectionInfo of document.querySelectorAll(".section.course-section.main.section-summary>.content>.section-summary-activities")) {
    //sectionInfo.querySelector("").remove();
    let progression = sectionInfo.textContent;
    if (!progression.includes("Progression")) {
        sectionInfo.remove();
        continue;
    }
    let progressCompleted = parseInt(progression.match(/\d+(?= \/ \d+$)/gm)[0]);
    let progressTotal = parseInt(progression.match(/\d+$/gm)[0]);
    if (progressCompleted === 0 && progressTotal > 0) {
        sectionInfo.innerHTML = `<span class='text-info'>💡 ${progressTotal} quêtes à compléter.</span>`;
    }
    else if (progressCompleted === progressTotal) {
        sectionInfo.innerHTML = "<span class='text-success'>Module complété!<br>🎉 Félicitations! </span>";
    }
    else if (progressCompleted === 1) {
        sectionInfo.innerHTML = "<span class='text-info'>Première quête complétée!</span><br><span class='text-warning'>✨ Plus que " + (progressTotal - progressCompleted) + " à faire.</span>";
    }
    else if (progressCompleted === progressTotal - 1) {
        sectionInfo.innerHTML = "<span class='text-warning'>" + progressCompleted + " quêtes complétées!</span><br><span class='text-danger'>🚀 Il ne t'en reste qu'une seule à faire!</span>";
    }
    else {
        sectionInfo.innerHTML = "<span class='text-info'>" + progressCompleted + " quêtes complétées.</span><br><span class='text-warning'>✨ Plus que " + (progressTotal - progressCompleted) + " à faire!</span>";
    }
}
if (!tests.isEditMode() && !(tests.isRoleCourseEditor() && debug.enabled)) {
    $(".hiddenactivity>div:nth-child(2)>span.badge").hide();
}
