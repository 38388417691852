import * as tests from "./tests";
import * as param from "./_param";
export const enabled = param.debugEnabled && tests.isRoleCourseEditor();
/***
 * Append a debug label to the specified module section node.
 * @param section The root section node. Usually contains the class .course-section.
 * @param badgeColorClass Bootstrap named context colors (primary, warning, etc).
 * @param text The label text.
 */
export function appendModuleDebugLabel(section, badgeColorClass, text) {
    if (!enabled)
        return;
    let label = document.createElement("span");
    let orderNum = section.querySelectorAll("div.course-section-header>div.sectionbadges>span").length + 1;
    label.classList.add("badge", "badge-pill", "badge-" + badgeColorClass, "order-" + orderNum);
    label.innerText = text;
    let labelWrapper = section.querySelector("div.course-section-header>div.sectionbadges");
    if (labelWrapper === null) {
        labelWrapper = document.createElement("div");
        labelWrapper.classList.add("my-1", "d-flex", "align-items-center");
        section.querySelector(".activity-item").appendChild(labelWrapper);
    }
    labelWrapper.append(label);
}
/***
 * Append a debug label to the specified activity node.
 * @param activity The root node. Usually contains the class .activity-wrapper.
 * @param badgeColorClass Bootstrap named context colors (primary, warning, etc).
 * @param text The label text.
 */
export function appendActivityDebugLabel(activity, badgeColorClass, text) {
    if (!enabled)
        return;
    let label = document.createElement("span");
    label.classList.add("mt-1", "badge", "badge-pill", "badge-" + badgeColorClass);
    label.innerHTML = text;
    let labelWrapper = activity.querySelector(".activity-item>.my-1.d-flex.align-items-center");
    if (labelWrapper === null) {
        labelWrapper = document.createElement("div");
        labelWrapper.classList.add("mt-1", "d-flex", "flex-column", "align-items-center");
        activity.querySelector(".activity-item").appendChild(labelWrapper);
    }
    if (!labelWrapper.classList.contains("flex-column")) {
        labelWrapper.classList.add("flex-column");
    }
    labelWrapper.append(label);
}
