export function show() {
    if (!this.classList.contains("spoiler")) {
        document.getElementById("modal-content").setAttribute("src", this.src);
        document.getElementById("modal-course").style.display = "block";
    }
    else {
        this.classList.remove("spoiler");
    }
}
export function hide() {
    document.getElementById("modal-course").style.display = "none";
    document.getElementById("modal-content").setAttribute("src", "");
}
