import $ from "$";
import * as cookie from "../utils/cookie";
import * as tests from "../utils/tests";
export function init() {
    // noinspection JSJQueryEfficiency
    $(".sliderlink").append($("<div class='bookmarkButton'><i class=\"fa-solid fa-bookmark\"></i></div>"));
    $(".bookmarkButton").bind("click", e => {
        let slCurrent = $(e.target.closest(".sliderlink"));
        let courselink_jq = $(".breadcrumb-item:nth-child(2)>a");
        let courselink;
        if (courselink_jq === null || courselink_jq.length === 0)
            courselink_jq = $(".breadcrumb-item:first-child>a");
        if (courselink_jq !== null && courselink_jq.length > 0)
            courselink = courselink_jq[0];
        let s = slCurrent.index();
        let p_match = window.location.href.match(/(?<=[?&]id=)\d+/g);
        let p;
        let c_match = courselink.getAttribute("href").match(/(?<=[?&]id=)\d+/g);
        let c;
        let m_match = courselink.getAttribute("href").match(/(?<=[#?&]section[\-=])\d+/g);
        let m;
        if (p_match !== null && p_match.length > 0)
            p = p_match.values().next().value;
        if (c_match !== null && c_match.length > 0)
            c = c_match.values().next().value;
        if (m_match !== null && m_match.length > 0)
            m = m_match.values().next().value;
        let value = "s" + s + "p" + p + "c" + c + "m" + m + ",";
        if (slCurrent.hasClass("bookmarked")) {
            slCurrent.find(".bookmarkButton")[0].classList.add("retracting");
            setTimeout(() => {
                slCurrent.find(".bookmarkButton")[0].classList.remove("retracting");
                slCurrent[0].classList.remove("bookmarked");
                cookie.set("sl-bookmarks", cookie.get("sl-bookmarks").replace(value, ""));
            }, 200);
        }
        else {
            slCurrent.find(".bookmarkButton")[0].classList.add("retracting");
            setTimeout(() => {
                slCurrent.find(".bookmarkButton")[0].classList.remove("retracting");
                slCurrent[0].classList.add("bookmarked");
                cookie.set("sl-bookmarks", cookie.get("sl-bookmarks") + value);
            }, 200);
        }
        // console.log(value);
        //s = sliderlinkid (order on page)
        //p = pageid
        //c = courseid (chapter)
        //m = sectionid (module)
    });
    let bookmarks = cookie.get("sl-bookmarks");
    console.log(bookmarks);
    if (!tests.isEmptyOrSpaces(bookmarks)) {
        let bookmarksList = bookmarks.split(",");
        for (let bm of bookmarksList) {
            let p_match = bm.match(/(?<=p)\d+/g);
            let p;
            let p_curr_match = window.location.href.match(/(?<=[?&]id=)\d+/g);
            let p_curr;
            if (p_match !== null && p_match.length > 0)
                p = p_match.values().next().value;
            if (p_curr_match !== null && p_curr_match.length > 0)
                p_curr = p_curr_match[0];
            if (p_curr === p) {
                let s_match = bm.match(/(?<=s)\d+/g);
                let s;
                if (s_match !== null && s_match.length > 0) {
                    s = parseInt(s_match.values().next().value);
                    $($(".sliderlink")[s])[0].classList.add("bookmarked");
                }
            }
            else {
                let c_match = window.location.href.match(/(?<=[?&]id=)\d+/g);
                let c;
                if (c_match !== null && c_match.length > 0)
                    c = c_match.values().next().value;
                let m_match = window.location.href.match(/(?<=[#?&]section[\-=])\d+/g);
                /* Fixme:  Will break on single-section modules*/
                if (m_match !== null && m_match.length > 0) {
                    let m;
                    m = m_match.values().next().value;
                    for (let a of document.querySelectorAll(".activityname>a")) {
                        let a_curr_match = a.getAttribute("href").match(/(?<=[?&]id=)\d+/g);
                        let a_curr;
                        if (a_curr_match !== null && a_curr_match.length > 0)
                            a_curr = a_curr_match.values().next().value;
                        if (a_curr === p) {
                            console.log("almostthere");
                            let activityItem = $(a.closest(".activity-item"));
                            let bookmarkIconLabel = activityItem.find(".quest-bookmark-icon-label");
                            if (bookmarkIconLabel !== null && bookmarkIconLabel.length > 0) {
                                bookmarkIconLabel.text(parseInt(bookmarkIconLabel.text()) + 1);
                            }
                            else {
                                console.log("shouldwork");
                                activityItem.find(".activityiconcontainer").append($(`<div class='quest-bookmark-icon'>
                                <span class="quest-bookmark-icon-label">1</span>
                                <i class="fa fa-bookmark"></i>
                            </div>`));
                            }
                            break;
                        }
                    }
                }
                else {
                    /* Todo : implement for modules */
                }
            }
        }
    }
}
