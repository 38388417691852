import { cloneElement } from "../utils/utils";
import * as tests from "../utils/tests";
import * as lightbox from "../components/lightbox";
import $ from "$";
import { isEmptyOrSpaces, isPictureOrIFrame, isTextParamTrue } from "../utils/tests";
import * as cookie from "../utils/cookie";
import { appendItem, bindCheckboxToCookie, TYPE } from "./user_settings";
import * as sl_func from "../components/sliderlinks";
import * as tts from "./text_to_speech";
const textElementsQuery = `
        #page-content .generalbox p,
        #page-content .generalbox ul,
        #page-content .generalbox img,
        #page-content .generalbox h5,
        #page-content .generalbox h4,
        #page-content .generalbox h3,
        #page-content .generalbox h2,
        #page-content .generalbox h1
    `;
export function init() {
    if (isTextParamTrue("tmp_should_reset_ux") || isEmptyOrSpaces(cookie.get("newux"))) {
        cookie.set("newux", "true");
    }
    appendItem(TYPE.CHECKBOX, "newux", "Nouvel UX", "display_submenu");
    bindCheckboxToCookie("newux", "newux", checked, unchecked);
}
function checked() {
    if (document.querySelector("#oldUxContainer")) {
        switchToNewUX();
    }
    else {
        if (document.querySelector(".sliderlink") !== null) { // If page has sliderlinks
            sl_func.initSliderlinkClick();
            sl_func.bindScrollspy();
            sl_func.resizeSlider();
            window.onresize = sl_func.resizeSlider;
            sl_func.disableRegularScroll();
        }
        else if (document.querySelector("#page-mod-page-view") !== null
            && document.querySelector("#page-content div[role=\"main\"] img") !== null
            && document.querySelector('iframe[src^="https://replit.com/"]') === null
            && document.querySelector('.accordion') === null
            && document.querySelector('.breadcrumb-item>a[href="https://moodle.studioxp.ca/course/view.php?id=168"]') === null
            && document.querySelector('.breadcrumb-item>a[href="https://moodle.studioxp.ca/course/view.php?id=176"]') === null
            && document.querySelector('.breadcrumb-item>a[href="https://moodle.studioxp.ca/course/view.php?id=177"]') === null
            && document.querySelector('.breadcrumb-item>a[href="https://moodle.studioxp.ca/course/view.php?id=180"]') === null
            && document.querySelector('body.cmid-5600') === null
            && document.querySelector('body.cmid-2566') === null
            && !tests.isTextParamTrue(document.querySelector(".activity-meta-info #disable-ux-conversion"))) { // Else if page should be converted
            createOldToNew();
            sl_func.initSliderlinkClick();
            sl_func.bindScrollspy();
            sl_func.resizeSlider();
            window.onresize = sl_func.resizeSlider;
            sl_func.disableRegularScroll();
            tts.appendSliderlinkButtons();
            switchToNewUX();
        } // Else, page doesn't need sliderlinks. Old format needs to be preserved.
    }
}
function unchecked() {
    if (document.querySelector("#oldUxContainer")) {
        switchToOldUX();
    }
    else if (document.querySelector(".slider")) {
        createNewToOld();
        switchToOldUX();
    }
}
function switchToNewUX() {
    document.querySelector("#oldUxContainer").style.display = "none";
    document.querySelector("#newUxContainer").style.display = "block";
    sl_func.resizeSlider();
    window.onresize = sl_func.resizeSlider;
    sl_func.disableRegularScroll();
}
function switchToOldUX() {
    document.querySelector("#oldUxContainer").style.display = "block";
    document.querySelector("#newUxContainer").style.display = "none";
    window.onresize = null;
    sl_func.enableRegularScroll();
}
function createNewToOld() {
    let columns = document.querySelector(".slider").parentElement.parentElement.querySelectorAll(".col-md-6");
    let container = null;
    for (let i = 0; i < columns.length; i++) {
        if (columns[i].querySelector(".sliderlink")) {
            container = columns[i];
            break;
        }
    }
    if (container === null) {
        console.error("Error in CreateNewToOld!");
        return;
    }
    //let container = columns.querySelector(".col-md-6:has(.sliderlink)");
    let paragraphs = container.children;
    let oldContainer = $(`<div class="box py-3 generalbox center clearfix" id="oldUxContainer"></div>`)[0];
    for (let el of paragraphs) {
        let newRow = document.createElement("div");
        newRow.classList.add("row-fluid");
        let new_el = cloneElement(el);
        newRow.appendChild(new_el);
        new_el.classList.remove("sliderlink");
        new_el.classList.add("col-md-6");
        let imgDiv = document.createElement("div");
        imgDiv.classList.add("col-md-6");
        let imgQuery = el.getAttribute("href") + " img";
        let img = document.querySelector(imgQuery);
        if (img)
            imgDiv.appendChild(cloneElement(img));
        newRow.appendChild(imgDiv);
        oldContainer.appendChild(newRow);
    }
    let newGeneralbox = container.closest(".generalbox");
    newGeneralbox.id = "newUxContainer";
    newGeneralbox.style.display = "none";
    newGeneralbox.parentElement.appendChild(oldContainer);
    //document.querySelector(".slider").remove();
}
function createOldToNew() {
    let imgHtmlElements = [];
    let textHtmlElements = [];
    if (document.querySelector(".row-fluid")) { // Page contains rows. Regular-ish case, can easily infer page content order.
        let pageContent = document.querySelectorAll("#page-content div[role=\"main\"] .generalbox>div.no-overflow>*");
        pageContent.forEach(el => {
            let allRows = el.querySelectorAll(".row-fluid");
            if (allRows.length > 0) {
                allRows.forEach(row => {
                    if (row.querySelector(".row-fluid"))
                        return; /* Don't handle recursively - only once. */
                    if (!tests.isEmptyOrSpaces(row)) {
                        /* document.querySelectorAll("#page-content br:last-child, #page-content br:first-child, #page-content p, #page-content ul, #page-content li").forEach(potentialBlank => {
                            if (tests.isEmptyOrSpaces(potentialBlank) && !tests.isPictureOrIFrame(potentialBlank))
                                potentialBlank.remove();
                        }); */
                        //row.firstElementChild.innerHTML = row.firstElementChild.innerHTML.replaceAll(/<.*>\s*<br>\s*<.*>/gm, "");
                        if (!tests.isEmptyOrSpaces(row.firstElementChild)) {
                            row.firstElementChild.innerHTML = row.firstElementChild.innerHTML.replace(/^\s*(?:<br>\s*)+|(?:<br>\s*)+(?!\S*\s)/gm, "");
                            if (!tests.isEmptyOrSpaces(row.firstElementChild))
                                textHtmlElements.push(cloneElement(row.firstElementChild));
                        }
                    }
                    if (tests.isPictureOrIFrame(row.lastElementChild)) {
                        row.lastElementChild.querySelectorAll("img, iframe, video").forEach(e => {
                            imgHtmlElements.push(cloneElement(e));
                        });
                    }
                    else if (tests.isH5PIframe(row.lastElementChild)) { // Append iFrames in text
                        row.lastElementChild.querySelectorAll("img, iframe, video").forEach(e => {
                            textHtmlElements.push(cloneElement(e));
                        });
                    }
                });
            }
            else if (tests.isEmptyOrSpaces(el)) { // No text means it must be empty, or be another media.
                if (tests.isPictureOrIFrame(el)) { // Append images and videos in slides
                    imgHtmlElements.push(cloneElement(el));
                }
                else if (tests.isH5PIframe(el)) { // Append iFrames in text
                    textHtmlElements.push(cloneElement(el));
                } // else, if empty and not another type of media, must be a blank.
            }
            else { // Not empty. Probably a loose « P » element.
                textHtmlElements.push(cloneElement(el));
            }
            for (let i = textHtmlElements.length; i < imgHtmlElements.length; i++) { // Create necessary remaining sliderlinks
                let suite = document.createElement("p");
                suite.innerHTML = "<em style='color:#888'>(suite)</em>";
                textHtmlElements.push(suite);
            }
            for (let i = imgHtmlElements.length; i < textHtmlElements.length; i++) { // Fill with empty images (turns text into an « alert-secondary » element).
                imgHtmlElements.push(null);
            }
        });
    }
    else { // MCreator and other vertical content
        let textContainer = document.createElement("div");
        let leftoverText = false;
        /* document.querySelectorAll("#page-content br:last-child, #page-content br:first-child, " + textElementsQuery).forEach(potentialBlank => {
            if (tests.isEmptyOrSpaces(potentialBlank) && !(potentialBlank instanceof HTMLImageElement || potentialBlank.querySelector("img")))
                potentialBlank.remove();
        }); */
        document.querySelectorAll(textElementsQuery).forEach(e => {
            if (e.closest(".activity-meta-info"))
                return;
            if (e instanceof HTMLImageElement || e.querySelector("img")) {
                if (e.querySelector("img") === null)
                    return;
                imgHtmlElements.push(cloneElement(e));
                if (!tests.isEmptyOrSpaces(textContainer)) {
                    textHtmlElements.push(cloneElement(textContainer));
                }
                else {
                    let suite = document.createElement("p");
                    suite.innerHTML = "<em style='color:#888'>(suite)</em>";
                    textHtmlElements.push(suite);
                }
                textContainer = document.createElement("div");
                leftoverText = false;
            }
            else {
                textContainer.appendChild(cloneElement(e));
                leftoverText = true;
            }
        });
        // Cases where text isn't separated in different tags (grr)
        if (textHtmlElements.length === 0) {
            let html = document.querySelector('#page-content div[role="main"] div.no-overflow').innerHTML;
            for (let e of html.split(/<img.*?>/gm)) {
                e = e.replace(/^\s*(?:<br>\s*)+|(?:<br>\s*)+(?!\S*\s)/gm, "");
                //e = e.replaceAll(/<.*>\s*<br>\s*<.*>/gm, "");
                let p = document.createElement("p");
                if (e === "")
                    p.innerHTML = "<em style='color:#888'>(suite)</em>";
                else
                    p.innerHTML = e;
                textHtmlElements.push(p);
            }
            document.querySelectorAll("#page-content div[role=\"main\"] div.no-overflow img").forEach(e => {
                imgHtmlElements.push(cloneElement(e));
            });
            for (let i = 0; i < (textHtmlElements.length - imgHtmlElements.length); i++) {
                imgHtmlElements.push(null);
            }
        }
        if (leftoverText) {
            imgHtmlElements.push(null);
            textHtmlElements.push(cloneElement(textContainer));
        }
    }
    if (textHtmlElements.length !== imgHtmlElements.length) {
        console.error(`Sliderlink generation error! Image and text array lengths don't match.
            Text count : ` + textHtmlElements.length + `
            Image count : ` + imgHtmlElements.length);
        return;
    }
    //## Formatting and generation
    let bsgrid = document.createElement("div");
    let row = document.createElement("div");
    let col_slider = document.createElement("div");
    let col_sliderlinks = document.createElement("div");
    let slider = document.createElement("div");
    let slides = document.createElement("div");
    bsgrid.classList.add("atto_bsgrid", "container-fluid");
    row.classList.add("row-fluid");
    col_slider.classList.add("col-md-6", "span6", "sticky");
    col_sliderlinks.classList.add("col-md-6");
    slider.classList.add("slider");
    slides.classList.add("slides");
    let firstSliderlink = true;
    textHtmlElements.forEach((el, i) => {
        if (isEmptyOrSpaces(el))
            return;
        let needSecondaryFormatting = true;
        let formattedTextElement = document.createElement("div");
        formattedTextElement.appendChild(el.cloneNode(true));
        for (let node of formattedTextElement.querySelectorAll("*")) {
            if (isEmptyOrSpaces(node) && !(node instanceof HTMLBRElement))
                node.remove();
        }
        let alertWrapper = el.querySelector(".alert");
        if (alertWrapper !== null) {
            if ($(alertWrapper).siblings().length === 0) {
                formattedTextElement = cloneElement(alertWrapper);
                needSecondaryFormatting = false;
            }
        }
        let sectionTitle = el.querySelector(".section-title");
        if (sectionTitle !== null) {
            if ($(sectionTitle).siblings().length === 0) {
                formattedTextElement = cloneElement(sectionTitle);
                needSecondaryFormatting = false;
            }
        }
        col_sliderlinks.appendChild(formattedTextElement);
        if (imgHtmlElements[i] !== null) {
            formattedTextElement.classList.add("sliderlink", "alert", "alert-secondary-outline", "alt-style");
            if (firstSliderlink) {
                formattedTextElement.classList.add("active");
                firstSliderlink = false;
            }
            formattedTextElement.setAttribute("href", "#img" + i);
            let formattedImageElement = document.createElement("div");
            formattedImageElement.id = "img" + i;
            let img = imgHtmlElements[i].querySelector("img");
            if (isEmptyOrSpaces(img) && isPictureOrIFrame(imgHtmlElements[i])) {
                img = imgHtmlElements[i];
            }
            if (img.classList.contains("spoiler")) {
                formattedImageElement.classList.add("spoiler");
                img.classList.remove("spoiler");
            }
            formattedImageElement.appendChild(img);
            img.addEventListener("click", lightbox.show);
            if (!(img instanceof HTMLIFrameElement)) {
                let bg = document.createElement("div");
                bg.setAttribute("style", `background : url("${img.getAttribute("src")}");
                `);
                formattedImageElement.appendChild(bg);
            }
            slides.appendChild(formattedImageElement);
        }
        else if (needSecondaryFormatting) {
            formattedTextElement.classList.add("alert", "alert-secondary");
        }
    });
    slider.appendChild(slides);
    col_slider.appendChild(slider);
    row.appendChild(col_sliderlinks);
    row.appendChild(col_slider);
    bsgrid.appendChild(row);
    let newGeneralbox = $(`<div class="box py-3 generalbox center clearfix" id="newUxContainer"></div>`)[0];
    newGeneralbox.appendChild(bsgrid);
    let oldGeneralbox = document.querySelector("#page-content .generalbox");
    oldGeneralbox.parentElement.appendChild(newGeneralbox);
    oldGeneralbox.id = "oldUxContainer";
    oldGeneralbox.style.display = "none";
    //document.querySelector("#page-content .generalbox>*:first-child").setAttribute("style", "display:none");
}
