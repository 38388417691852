// ## Chapter meta info
import * as tests from "../utils/tests";
import * as debug from "../utils/debug";
import $ from "$";
import * as param from "../utils/_param";
import * as locale from "../utils/locale";
import { isEmptyOrSpaces } from "../utils/tests";
export function formatChapter() {
    const chapterMetaInfo = document.querySelector(".chapter-meta-info");
    if (chapterMetaInfo !== null) {
        if (document.querySelector("#page-course-view-topics") && !document.querySelector("div.single-section")) {
            const chapterAlert = chapterMetaInfo.querySelector("#globalAlert");
            if (chapterAlert) {
                document.querySelector('#page-header').after(chapterAlert);
                chapterAlert.querySelector(".alert-success").insertBefore(document.querySelector(".page-header-headings>h1"), chapterAlert.querySelector(".alert-success>p:first-child"));
                document.querySelector("#page-header").style.display = "none";
            }
        }
    }
}
export function formatModules() {
    const moduleMetaInfoList = document.querySelectorAll(".module-meta-info");
    for (const moduleMetaInfo of moduleMetaInfoList) {
        if (moduleMetaInfo !== null && document.querySelector("#page-course-view-topics") !== null) {
            let singleSection = document.querySelector("div.single-section");
            let section = moduleMetaInfo.closest(".section.course-section.main");
            if (singleSection) {
                const moduleAlert = moduleMetaInfo.querySelector("#moduleAlert .alert");
                if (moduleAlert) {
                    document.querySelector('#page-header').after(moduleAlert);
                    moduleAlert.insertBefore(document.querySelector("#page-navbar"), moduleAlert.querySelector(".alert-primary>p:first-child, .alert-primary-outline>p:first-child"));
                    moduleAlert.insertBefore(document.querySelector(".page-header-headings>h1"), moduleAlert.querySelector("#page-navbar"));
                    document.querySelector("#page-header").style.display = "none";
                }
            }
            if (tests.isTextParamTrue(moduleMetaInfo.querySelector("#remove_header"))
                && tests.isRoleTeacher() && !tests.isEditMode()) {
                moduleMetaInfo.closest('div[role="main"]').style.padding = "0";
                section.querySelector(".course-section-header").style.cssText += "display:none !important";
                section.querySelector(".content.course-content-item-content").style.margin = "0";
            }
            if (tests.isTextParamTrue(moduleMetaInfo.querySelector("#general_active"))
                && tests.isRoleTeacher() && !tests.isEditMode()
                && singleSection) {
                const generalSection = document.querySelector("#section-0");
                generalSection.style.cssText += "opacity:1; display:unset; margin-bottom:0;";
                let allSections = document.querySelectorAll(".section.course-section.main");
                allSections[allSections.length - 1].after(generalSection);
                const title_o = generalSection.querySelector("h3.sectionname");
                const title_n = document.createElement("h4");
                title_n.classList.add("section-title");
                title_n.style.cssText += "position: relative;\n" +
                    "    bottom: 0.3rem;\n" +
                    "    margin: 1rem 0;";
                if (title_o.querySelector("a") !== null) {
                    title_n.innerText = title_o.querySelector("a").innerText;
                }
                else if (!tests.isEmptyOrSpaces(title_o)) {
                    title_n.innerText = title_o.innerText;
                }
                title_o.parentElement.replaceChild(title_n, title_o);
                if (section.id.includes("section-0")) {
                    debug.appendModuleDebugLabel(section, "danger", "Displaying general section on ALL pages");
                }
                else {
                    debug.appendModuleDebugLabel(section, "danger", "Displaying general section on this page");
                }
            }
            if (tests.isTextParamTrue(moduleMetaInfo.querySelector("#teacherHidden"))) {
                debug.appendModuleDebugLabel(section, "danger", "Module hidden unless editing");
                if (!(tests.isRoleCourseEditor() && tests.isEditMode())) {
                    section.classList.add("display-none");
                }
            }
            if (param.moduleBackgrounds && !tests.isEditMode()) {
                let background = moduleMetaInfo.querySelector("#module_bg>*");
                if (!tests.isPictureOrIFrame(background))
                    break;
                if ($(".single-section").length === 0) {
                    section.style.cssText += `background: linear-gradient( rgba(40,40,40,.4), rgba(40,40,40,.4) ), url(${background.getAttribute("src")}) #444;
                        background-size: cover;
                        box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px, #303030 0px 0px 40px inset;
                        text-shadow: #303030 0px 0px 20px, #303030 2px 2px 10px, #000 2px 2px 4px;`;
                }
                else {
                    console.log("NOT");
                    $("#page").attr('style', `background: linear-gradient( rgba(30,30,30,.6), rgba(30,30,30,.6) ), url(${background.getAttribute("src")}) 0% 0% / cover rgb(68, 68, 68) !important;`);
                    $("#region-main, #topofscroll, .activity.label>.activity-item, .breadcrumb").attr('style', "background : transparent !important; border:none;");
                }
            }
        }
    }
    document.querySelectorAll(".module-meta-info").forEach(e => {
        let summaryText = e.closest(".course-description-item.summarytext");
        if (summaryText !== null && !tests.isEmptyOrSpaces(summaryText.parentElement.querySelector(".section_availability"))) {
            e.closest(".course-description-item.summarytext").remove();
        }
        else {
            /* e.closest("div[data-for=\"sectioninfo\"]").remove(); */
            // Testing... Module meta info sections should already be invisible anyway!
        }
    });
}
export function formatActivities() {
    let metaInfoList = document.querySelectorAll(".activity-meta-info");
    metaInfoList.forEach(metaInfo => {
        let activityItem = metaInfo.closest(".activity-item");
        let icon;
        if (activityItem) {
            icon = activityItem.querySelector(".activityiconcontainer");
        }
        else {
            icon = document.querySelector(".activityiconcontainer");
        }
        if (icon !== null) {
            icon.style.cssText += `padding:10px; background-color: ${metaInfo.querySelector("#activity-color").textContent};`;
            let iconImg = icon.querySelector("img");
            let iconClasses = metaInfo.querySelector("#icon-classes");
            if (iconClasses && iconClasses.textContent.length !== 0) {
                let classesArray = iconClasses.textContent.trim().split(" ");
                classesArray.forEach(myClass => {
                    let smallText_header = document.querySelector(".page-header-headings .text-muted.small");
                    let questNumber = metaInfo.querySelector("#activity-questnumber");
                    if (questNumber) {
                        let label = questNumber.textContent;
                        if (label.includes("Quête")) {
                            label = locale.format("fr_ca", label)
                                + locale.format("en", label.replace("Quête", "Quest"));
                        }
                        if (smallText_header) {
                            smallText_header.classList.add(myClass);
                            smallText_header.innerHTML = label;
                        }
                        if (activityItem) {
                            if (activityItem.querySelector(".activitytitle .media-body .text-uppercase.small"))
                                activityItem.querySelector(".activitytitle .media-body .text-uppercase.small").innerHTML = label;
                        }
                    }
                    icon.classList.add(myClass);
                    if (activityItem) {
                        activityItem.classList.add(myClass);
                        if (questNumber) {
                            activityItem.classList.add("custom-quest-label");
                        }
                    }
                });
            }
            if (iconImg !== null) {
                if (metaInfo.querySelector("#activity-icon>img") !== null) {
                    iconImg.src = metaInfo.querySelector("#activity-icon>img").getAttribute("src");
                }
                else {
                    let para = document.createElement("p");
                    let text = document.createTextNode(metaInfo.querySelector("#activity-icon").textContent);
                    para.style.cssText += "font-size: 28px; line-height: 28px; height: 100%; margin-bottom: 0;";
                    para.appendChild(text);
                    iconImg.parentElement.replaceChild(para, iconImg);
                }
                iconImg.style.cssText += "height:auto; width:100%; padding:0; filter:none;";
            }
        }
        if (metaInfo.parentNode.children.length === 1) {
            let description = metaInfo.closest(".description");
            let meta_description = metaInfo.querySelector("#activity-description");
            if (description) {
                if (meta_description && !isEmptyOrSpaces(meta_description.innerText)) {
                    if (meta_description.querySelector(".badge") !== null) {
                        meta_description.innerHTML = meta_description.innerHTML.replace(/>\s*&nbsp;\s*</gm, "><");
                        meta_description.innerHTML = meta_description.innerHTML.replace(/rounded/gm, "squared");
                    }
                    description.querySelector(".description-inner").innerHTML = meta_description.innerHTML;
                }
                else if (isEmptyOrSpaces(description.querySelector(".availabilityinfo"))) {
                    description.remove();
                }
                else {
                    description.querySelector(".activity-altcontent.course-description-item:first-child").remove();
                }
            }
        }
        if (!activityItem)
            return;
        if (tests.isTextParamTrue(metaInfo.querySelector("#append_to_general"))) {
            if (document.querySelector("#section-0") !== null
                && tests.isRoleTeacher()) {
                if (!tests.isEditMode())
                    $("#section-0 .activity-wrapper:last-child").after($(activityItem.parentElement));
            }
            else {
                activityItem.parentElement.style.display = "none !important";
            }
            debug.appendActivityDebugLabel(activityItem.parentElement, "danger", "Appended to general section");
        }
        if (tests.isTextParamTrue(metaInfo.querySelector("#copy_link"))) {
            let bypass = metaInfo.querySelector("#link_bypass");
            if (!isEmptyOrSpaces(bypass)) {
                activityItem.querySelector("a").href = bypass.innerText;
            }
            let link = activityItem.querySelector("a").href;
            let copyButton = document.createElement("button");
            copyButton.classList.add("btn", "btn-secondary-outline");
            copyButton.setAttribute("href", link);
            copyButton.innerHTML = `<svg style="width:16px;filter:invert(1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z"/></svg>`;
            copyButton.style.cssText = "background-color:#444;padding:0.2rem 0.5rem;border-radius:0.5rem;position:absolute;top:2px;right:2px;z-index:2;box-shadow:none !important";
            copyButton.onclick = e => {
                e.preventDefault();
                navigator.clipboard.writeText((e.target).closest("button").getAttribute("href"));
                copyButton.style.backgroundColor = "#222";
                copyButton.innerHTML = `<svg style="width:16px;filter:invert(1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
                setTimeout(() => {
                    copyButton.style.backgroundColor = "#444";
                    copyButton.innerHTML = `<svg style="width:16px;filter:invert(1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z"/></svg>`;
                }, 1000);
            };
            activityItem.appendChild(copyButton);
            debug.appendActivityDebugLabel(activityItem.parentElement, "primary", "Displaying a copy button");
        }
        let reqLang = metaInfo.querySelector("#required-language");
        if (reqLang !== null) {
            let htmlLang = document.querySelector("html").getAttribute("lang");
            debug.appendActivityDebugLabel(activityItem.parentElement, "success", "<span class='white-outline'>🌎</span> [" + reqLang.textContent.toUpperCase() + "]");
            if (!reqLang.textContent.includes(htmlLang) && !tests.isEditMode()) {
                activityItem.parentElement.remove();
            }
        }
    });
}
export function CleanEmptyDescriptions() {
    document.querySelectorAll(".activity-item>.description").forEach(e => {
        let h = e;
        if (tests.isEmptyOrSpaces(h.innerText))
            h.style.display = "none";
    });
}
