import $ from "$";
import * as tests from "../utils/tests";
import scrollIntoView from "scroll-into-view-if-needed";
const sliderResizeMinigun_MaximumIterations = 50;
const sliderResizeMinigun_Delay = 50;
let sliderResizeMinigun_Iterator = 0;
// ## Scroll parameters
// const keys = {33: 1, 34: 1, 35: 1, 36: 1, 37: 1, 38: 1, 39: 1, 40: 1};
// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    }));
}
catch (e) {
}
const wheelOpt = supportsPassive ? { passive: false } : false;
let allSliderlinks = null;
let hoveringSliderlinks = false;
let isScrollThrottling = false;
let slider, slides, sliderlinkColumn;
export function resizeSlider() {
    if (slider === null || slides === null || sliderlinkColumn === null) {
        slider = document.getElementsByClassName("slider")[0];
        slides = document.getElementsByClassName("slides")[0];
        if (document.querySelector(".col-md-6:first-child .slider")) {
            sliderlinkColumn = slider.closest(".atto_bsgrid").querySelector(".col-md-6:last-child");
        }
        else {
            sliderlinkColumn = slider.closest(".atto_bsgrid").querySelector(".col-md-6:first-child");
        }
    }
    let sliderlinkColHeight = parseInt(document.defaultView.getComputedStyle(sliderlinkColumn).height);
    let windowInnerHeight = window.innerHeight;
    let availableSpace = windowInnerHeight - slider.getBoundingClientRect().top - 24;
    let sliderlinkColBottom = sliderlinkColumn.getBoundingClientRect().bottom;
    let maxHeight;
    if (sliderlinkColHeight > windowInnerHeight) {
        maxHeight = windowInnerHeight - 109;
    }
    else {
        maxHeight = sliderlinkColHeight;
    }
    if (availableSpace > maxHeight || (sliderlinkColBottom - 109) < maxHeight) {
        let adjustment = 0;
        if ((sliderlinkColBottom - 109) < maxHeight) {
            adjustment = (maxHeight - sliderlinkColBottom) + 85;
        }
        slides.setAttribute("style", `height: ${maxHeight - adjustment}px`);
    }
    else {
        slides.setAttribute("style", `height : ${availableSpace}px`);
    }
}
function resizeSliderMinigun() {
    sliderResizeMinigun_Iterator = 0;
    let intervalObj = setInterval(resizeSliderMinigunIntervalFunc, sliderResizeMinigun_Delay);
    function resizeSliderMinigunIntervalFunc() {
        resizeSlider();
        sliderResizeMinigun_Iterator++;
        if (sliderResizeMinigun_Iterator >= sliderResizeMinigun_MaximumIterations) {
            clearInterval(intervalObj);
        }
    }
}
export function bindScrollspy() {
    allSliderlinks = document.querySelectorAll(".sliderlink");
    document.querySelector(".sliderlink").closest(".col-md-6").addEventListener("mouseenter", () => {
        hoveringSliderlinks = true;
    });
    document.querySelector(".sliderlink").closest(".col-md-6").addEventListener("mouseleave", () => {
        hoveringSliderlinks = false;
    });
}
function activatePreviousSliderlink() {
    let sl = $(".sliderlink");
    for (let i = 1; i < sl.length; i++) {
        if (sl[i].classList.contains("active")) {
            let previousSliderlink = sl[i - 1];
            if (tests.isBottomInViewport(previousSliderlink)) {
                return activateSliderlink(previousSliderlink, true, 'end');
            }
            else
                return false;
        }
    }
    return false;
}
export function activateNextSliderlink() {
    let sl = $(".sliderlink");
    for (let i = 0; i < sl.length - 1; i++) {
        if (sl[i].classList.contains("active")) {
            let nextSliderlink = sl[i + 1];
            if (tests.isTopInViewport(nextSliderlink)) {
                return activateSliderlink(nextSliderlink, true, 'start');
            }
            else
                return false;
        }
    }
    return false;
}
export function activateFirstSliderlink() {
    return activateSliderlink($(".sliderlink")[0], true);
}
export function activateLastSliderlink() {
    let sl = $(".sliderlink");
    return activateSliderlink(sl[sl.length - 1], true);
}
export function handleSliderlinkScroll(e) {
    resizeSlider();
    if (isScrollThrottling) {
        e.preventDefault();
        return;
    }
    if (e.deltaY < 0) {
        if (activatePreviousSliderlink())
            e.preventDefault();
    }
    else if (e.deltaY > 0) {
        if (activateNextSliderlink())
            e.preventDefault();
    }
    //timeoutScroll();
}
/* function throttleScroll(length:number) {
    isScrollThrottling = true;
    setTimeout(() => {
        isScrollThrottling = false;
    }, length)
}*/
export async function activateSliderlink(sliderlink, scroll, position) {
    if (!sliderlink.classList.contains("sliderlink"))
        sliderlink = sliderlink.closest(".sliderlink");
    const link = sliderlink.getAttribute("href");
    const element = document.querySelector(link);
    document.getElementsByClassName("slides")[0].scrollLeft = element.offsetLeft;
    if (sliderlink.classList.contains("active")) {
        return false;
    }
    else {
        let links = document.getElementsByClassName("sliderlink");
        for (let j = 0; j < links.length; j++) {
            if (links[j].classList.contains('active')) {
                links[j].classList.remove('active');
                links[j].classList.add('viewed');
            }
        }
        sliderlink.classList.add('active');
        if (scroll) {
            resizeSliderMinigun();
            isScrollThrottling = true;
            //await import("scroll-into-view-if-needed").then(async a => {
            await scrollIntoView(sliderlink, {
                behavior: 'smooth',
                scrollMode: 'if-needed',
                block: position ? position : 'center',
                inline: 'center',
                duration: 300,
            });
            //});
            isScrollThrottling = false;
            //throttleScroll(300);
        }
        return true;
    }
}
/* export function activateThisSliderlink(scroll:boolean) {
    return activateSliderlink(this, scroll);
} */
export function initSliderlinkClick() {
    let sliderlinks = document.getElementsByClassName("sliderlink");
    for (let i = 0; i < sliderlinks.length; i++) {
        sliderlinks[i].addEventListener("click", e => {
            activateSliderlink(e.target, true).then(() => { });
        });
    }
    // ## Handle slider resizing //
    slider = document.getElementsByClassName("slider")[0];
    slides = document.getElementsByClassName("slides")[0];
    sliderlinkColumn = null;
    if (document.querySelector(".col-md-6:first-child .slider")) {
        sliderlinkColumn = document.querySelector(".atto_bsgrid.active .col-md-6:last-child");
    }
    else {
        sliderlinkColumn = document.querySelector(".atto_bsgrid.active .col-md-6:first-child");
    }
    /*if(document.querySelector("div.activity-header") && document.querySelector("body.pagelayout-admin") == null){
        document.querySelector("#page").addEventListener('scroll', resizeSlider);
        resizeSlider();
    }*/
}
// call this to Disable
export function disableRegularScroll() {
    window.addEventListener('DOMMouseScroll', handleSliderlinkScroll, false); // older FF
    window.addEventListener('wheel', handleSliderlinkScroll, wheelOpt); // modern desktop
    // window.addEventListener('touchmove', handleSliderlinkScroll, wheelOpt); // mobile
    window.addEventListener('keydown', handleSliderlinkScrollForScrollKeys, false); // keys
}
// call this to Enable
export function enableRegularScroll() {
    window.removeEventListener('DOMMouseScroll', handleSliderlinkScroll, false);
    window.removeEventListener('wheel', handleSliderlinkScroll, wheelOpt);
    // window.removeEventListener('touchmove', handleSliderlinkScroll, wheelOpt);
    window.removeEventListener('keydown', handleSliderlinkScrollForScrollKeys, false);
}
function handleSliderlinkScrollForScrollKeys(e) {
    //if (keys[e.code]) {
    switch (parseInt(e.code)) {
        case 33:
        case 36:
            {
                activateFirstSliderlink().then(() => {
                    e.preventDefault();
                    return false;
                });
            }
            break;
        case 34:
        case 35:
            {
                activateLastSliderlink().then(() => {
                    e.preventDefault();
                    return false;
                });
            }
            break;
        case 37:
        case 38:
            {
                if (activatePreviousSliderlink()) {
                    e.preventDefault();
                    return false;
                }
            }
            break;
        case 39:
        case 40:
            {
                if (activateNextSliderlink()) {
                    e.preventDefault();
                    return false;
                }
            }
            break;
    }
    //}
}
