export function set(cName, cValue) {
    const d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000)); // hard-coded to a year; I don't need cookies that expire quicker.
    let expires = "expires=" + d.toUTCString();
    document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
}
export function get(cName) {
    let name = cName + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let cValue = c.substring(name.length, c.length);
            set(cName, cValue); // dirty hack to refresh the cookie :)
            return cValue;
        }
    }
    return "";
}
