import $ from "$";
import * as anim from "../utils/anim";
import * as cookie from "../utils/cookie";
import { isEmptyOrSpaces, isTextParamTrue, isRoleTeacher, isRoleCourseEditor } from "../utils/tests";
export var TYPE;
(function (TYPE) {
    TYPE[TYPE["CHECKBOX"] = 0] = "CHECKBOX";
    TYPE[TYPE["SUBMENU"] = 1] = "SUBMENU";
    TYPE[TYPE["RANGE"] = 2] = "RANGE";
    TYPE[TYPE["SELECT"] = 3] = "SELECT";
    TYPE[TYPE["BREAK"] = 4] = "BREAK";
})(TYPE || (TYPE = {}));
// todo : split and expose settings menu creation into different functions
//  in order to dynamically add elements elsewhere
function createBaseMenu() {
    let nav = $("#usernavigation");
    let settings = $(`<div>
        <ul id="user_settings_menu" class="dropdown-menu" role="menu">
            <li class="nav-item">
                <a role="menuitem" class="dropdown-item disabled" href="#" tabindex="-1">
                    <h3><strong style="color:#3498DB;">Paramètres expérimentaux</strong></h3>
                </a>
            </li>
            <!--<li class="nav-item">
                <a role="menuitem" class="dropdown-item" href="#" tabindex="-1">
                    <input type="checkbox"></input>Nouvel UX
                </a>
            </li>
            <li class="nav-item">
                <a role="menuitem" class="dropdown-item" href="#" tabindex="-1">
                    <input type="checkbox"></input>Dark mode
                </a>
            </li>
            <li class="nav-item">
                <a role="menuitem" data-reload-needed="true" class="dropdown-item" href="#" tabindex="-1">
                    <input type="checkbox"></input>Textes colorés
                </a>
            </li> -->
            <li style="display:none;" id="reloadNeededMessage" class="text-warning">
                <p style="margin:0.5rem 1.5rem;">
                    Rechargez la page pour appliquer les changements.
                </p>
            </li>
        </ul>
    </div>
    
    <i id="user_settings_btn" class="fa-duotone fa-screwdriver-wrench"></i>`);
    if (nav.find(".divider").length === 0) {
        nav.append($(`
            <div class="divider border-left h-75 align-self-center ml-1 mr-3"></div>
        `));
    }
    nav.append(settings);
}
export function appendItem(type, id, label, parentId, extraProperties) {
    let el;
    if (isEmptyOrSpaces(parentId))
        parentId = "user_settings_menu";
    /*if(isEmptyOrSpaces(id))
        id = "";

    if(isEmptyOrSpaces(extraProperties));
        extraProperties*/
    switch (type) {
        case TYPE.RANGE:
            {
                el = $(`<label for="${id}" class="form-label leaf">${label}</label><br>
                    <input style="width:100%;" type="range" class="form-range" ${extraProperties} id="${id}">`);
            }
            break;
        case TYPE.SUBMENU:
            {
                el = $(`<a role="menuitem" class="dropdown-item" for="${id}" href="#" tabindex="-1">${label}</a>
                <div class="dropdown-menu" style="width:100%;" id="${id}"></div>`);
            }
            break;
        case TYPE.SELECT:
            {
                el = $(`<label for="${id}" class="form-label">${label}</label><br>
                    <select style="width:100%;" class="form-select leaf" ${extraProperties} id="${id}"></select>`);
            }
            break;
        case TYPE.BREAK:
            {
                el = $("<br>");
            }
            break;
        case TYPE.CHECKBOX:
            {
                el = $(`<div class="form-check leaf">
                  <input class="form-check-input" type="checkbox" value="" id="${id}" ${extraProperties}>
                  <label class="form-check-label" for="${id}">
                    ${label}
                  </label>
                </div>`);
            }
            break;
    }
    if (typeof el === "undefined")
        return;
    if (type === TYPE.SUBMENU)
        bindDropdown(el[0]);
    if (parentId.includes("user_settings_menu")) {
        let li = $("<li role='menu' class='nav-item' style='width:100%'></li>");
        li.append(el);
        $("#user_settings_menu").append(li);
        return li[0];
    }
    else {
        $(`#${parentId}`).append(el);
        return el[0];
    }
}
function bindDropdown(el) {
    console.log("binding, for = " + el.getAttribute("for"));
    el.addEventListener("click", e => {
        $(`#${el.getAttribute("for")}`).toggleClass("show");
    });
}
export function bindCheckboxToCookie(checkbox_id, cookie_id, checked_func, unchecked_func, default_value) {
    let cVal = cookie.get(cookie_id);
    if (isEmptyOrSpaces(cVal) && !isEmptyOrSpaces(default_value)) {
        cookie.set(cookie_id, default_value);
        cVal = default_value;
    }
    if (isTextParamTrue(cVal)) {
        document.querySelector(`#${checkbox_id}`).checked = true;
        checked_func();
    }
    else {
        document.querySelector(`#${checkbox_id}`).checked = false;
        unchecked_func();
    }
    $(`#${checkbox_id}`).click(e => {
        let checked = document.querySelector(`#${checkbox_id}`).checked;
        //let checked = (<HTMLElement>e.target).closest(".form-check").querySelector("input").checked;
        cookie.set(cookie_id, checked.toString());
        if (checked) {
            checked_func();
        }
        else {
            unchecked_func();
        }
    });
}
function initDisplaySubmenu() {
    appendItem(TYPE.SUBMENU, "display_submenu", "Affichage");
    appendItem(TYPE.CHECKBOX, "limitedwidth", "Limiter la largeur des pages", "display_submenu");
    bindCheckboxToCookie("limitedwidth", "limitedwidth", () => {
        document.body.classList.add("limitedwidth");
    }, () => {
        document.body.classList.remove("limitedwidth");
    });
    if (isRoleTeacher() || isRoleCourseEditor()) {
        appendItem(TYPE.CHECKBOX, "debug_mode", "🪲 Activer le mode débug", "display_submenu");
        bindCheckboxToCookie("debug_mode", "debug_mode", () => {
            document.querySelector("#reloadNeededMessage").style.display = "block";
        }, () => {
            document.querySelector("#reloadNeededMessage").style.display = "block";
        }, "true");
    }
}
export function init() {
    createBaseMenu();
    $('#user_settings_btn').click(() => {
        $("#user_settings_menu").toggleClass("show");
        anim.doFaBounce(document.querySelector("#user_settings_btn"));
    });
    initDisplaySubmenu();
}
/* window.speechSynthesis.onvoiceschanged = () => {

} */ 
