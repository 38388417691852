export function isEmptyOrSpaces(obj) {
    if (!obj || typeof obj === "undefined")
        return true;
    if (typeof obj === "string" || obj instanceof String) {
        return !obj.match(/\S/gm);
    }
    if (typeof obj === "object" || obj instanceof HTMLElement) {
        if (obj.innerText)
            return !obj.innerText.match(/\S/gm);
        else if (obj instanceof HTMLBRElement || obj instanceof HTMLParagraphElement || obj instanceof HTMLHeadingElement)
            return true; /* The element should contain text, and it doesn't */
    }
    return false;
}
export function isPictureOrIFrame(node) {
    if (!node || typeof node === "undefined")
        return false;
    return node instanceof HTMLImageElement
        || node.querySelector("img") !== null
        || (node instanceof HTMLIFrameElement
            && node.getAttribute("src").indexOf("moodle.studioxp.ca") === -1)
        || (node.querySelector("iframe") !== null
            && !node.querySelector("iframe").src.includes("moodle.studioxp.ca"))
        || node.querySelector("video") !== null
        || node instanceof HTMLVideoElement;
}
export function isH5PIframe(node) {
    return node !== null && typeof node !== "undefined"
        && ((node instanceof HTMLIFrameElement
            && node.getAttribute("src").indexOf("moodle.studioxp.ca") !== -1)
            || (node.querySelector("iframe") !== null
                && node.querySelector("iframe").getAttribute("src") !== null
                && node.querySelector("iframe").getAttribute("src").indexOf("moodle.studioxp.ca") !== -1));
}
export function isTopInViewport(element) {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.top < (window.innerHeight || document.documentElement.clientHeight);
}
export function isBottomInViewport(element) {
    const rect = element.getBoundingClientRect();
    return rect.bottom > 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
}
export function isRoleCourseEditor() {
    return document.querySelector("body.role-editingteacher, body.role-manager") !== null;
}
export function isRoleTeacher() {
    return document.querySelector("body.role-editingteacher, body.role-manager, body.role-teacher") !== null;
}
export function isEditMode() {
    return document.querySelector("body.editing") !== null;
}
/***
 * Evaluates the contents of an object, comparing against "true".
 * Useful for establishing JS boolean variables in plain HTML.
 * @param param the object to evaluate (Node, String or Boolean)
 * @returns {boolean} returns true if parameter evaluates to "true", otherwise false.
 */
export function isTextParamTrue(param) {
    if (param !== null) {
        if (param === true) {
            return true;
        }
        else if (typeof param === "string") {
            if (param.includes("true"))
                return true;
        }
        else if (typeof (param.textContent) === "string") {
            if (param.textContent.includes("true"))
                return true;
        }
    }
    return false;
}
